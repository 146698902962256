<script>
  import AbstractMenu from '@/layouts/AbstractMenu'
  import { Logger } from 'aws-amplify'
  const logger = new Logger('EnduserMenu')

  export default {
    name: 'EnduserMenu',

    extends: AbstractMenu,

    data: () => {
      return {
        // menu things
        rounded: null,
        inset: true,
        mini: 1,
        section0: [{
          text: 'topMenuEU.myRequestServices',
          id: 'EU.topMenu.services',
          icon: 'mdi-card-account-details-outline',
          link: {
            name: 'EndUserMyServices',
          },
        },
        /*
         {
          text: 'topMenuEU.agenda',
          icon: 'mdi-calendar-month',
          link: {
            name: 'EndUserMyAgenda',
          },
        }, */
        ],
        side: 1,
        section1: [{
                     text: 'sideMenuEU.account',
                     id: 'EU.sideMenu.account',
                     icon: 'mdi-card-account-details-outline',
                     link: {
                       name: 'EndUserMyAccount',
                     },
                   },
                   {
                     text: 'sideMenuEU.parameters',
                     id: 'EU.sideMenu.parameters',
                     icon: 'mdi-cog',
                     link: {
                       name: 'EndUserParameters',
                     },
                   },
                   /*
                   {
                     text: 'sideMenuEU.param',
                     icon: 'mdi-calendar-month',
                     link: {
                       name: 'EndUserMyAppoint',
                     },
                   },
                   {
                     text: 'sideMenuEU.gdpr',
                     icon: 'mdi-laptop-off',
                     link: {
                       name: 'EndUserGdpr',
                     },
                   }, */
        ],
      }
    },

    created: () => {
      logger.info('EndUser Menu created')
    },
  }
</script>
